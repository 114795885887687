var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DEXT5Upload", {
    ref: "dextupload",
    attrs: {
      id: _vm.component_id,
      single: _vm.single,
      category: _vm.category,
      width: "100%",
      height: "200px",
      "file-info": _vm.value,
      "file-ext": _vm.fileExt,
      "category-info": _vm.catList,
    },
    on: { completed: _vm.uploadCompleted },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }