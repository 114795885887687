<template>
  <DEXT5Upload
    ref="dextupload"
    :id="component_id"
    :single="single"
    :category="category"
    width="100%"
    height="200px"
    @completed="uploadCompleted"
    :file-info="value"
    :file-ext="fileExt"
    :category-info="catList" />
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    single: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    catList: {
      type: Array,
      default: function () {
        return []
      }
    },
    fileExt: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    component_id () {
      if (typeof window.upload_component_cnt === 'undefined') {
        window.upload_component_cnt = 0
      }
      window.upload_component_cnt++
      const c = window.upload_component_cnt

      return `upload_com_id_${c}`
    },
    category () {
      return this.catList.length !== 0
    }
  },
  components: {
    DEXT5Upload: () => import('@/components/DEXT5Upload')
  },
  mounted () {
  },
  methods: {
    uploadCompleted (fileInfo) {
      this.$emit('completed', fileInfo)
    },
    openPopup () {
      const st = $('html').scrollTop()
      $(this.$refs.popup).data('scroll-top', st).fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    closePopup: function () {
      let file = this.$refs.dextupload.getFileInfo()
      this.$refs.dextupload.ResetDext()

      const st = $(this.$refs.popup).data('scroll-top')
      $(this.$refs.popup).fadeOut()
      $('body,html').removeAttr('style')
      $('html').scrollTop(st)
      return false
    }
  }
}
</script>
<style>
.DEXT_fiVe_UP_ly_wrapper{
  z-index: 99999999 !important;
}
</style>
